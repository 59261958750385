<template>
  <b-card title="Precisa de Ajuda ❔">
    <HTMLTitle :title="$t('system.help.page_title')"></HTMLTitle>
    <b-card-text>Esta é a sua página de ajuda.</b-card-text>
    <b-card-text>Conteúdo da Página de Ajuda</b-card-text>
  </b-card>
</template>

<script>
import { BCard, BCardText } from 'bootstrap-vue'
import HTMLTitle from '@core-md/layouts/components/HTMLTitle.vue'

export default {
  components: {
    BCard,
    BCardText,
    HTMLTitle,
  },
}
</script>

<style>

</style>
